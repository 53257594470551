import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AppMenu, AppMenuItem } from './components/AppMenu';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import * as Admin from './pages/Admin/Admin';
import * as Materials from './pages/Materials/Materials';
import * as Packs from './pages/Packs/Packs';
import * as Templates from './pages/Templates/Templates';
import * as Layouts from './pages/Layouts/Layouts';
import * as Stickers from './pages/Stickers/Stickers';
import * as Fonts from './pages/Fonts/Fonts';
import * as Backgrounds from './pages/Backgrounds/Backgrounds';
import * as Placeholders from './pages/Placeholders/Placeholders';
import * as Colors from './pages/Colors/Colors';
import * as Combos from './pages/Combos/Combos';
import * as Texts from './pages/Texts/Texts';
import * as Shapes from './pages/Shapes/Shapes';
import * as Search from './pages/Search/Search';
import * as Creators from './pages/Creators/Creators';
import * as Messaging from 'pages/Messaging/Messaging';
import * as Bundles from './pages/Bundles/Bundles';
import * as Gradients from './pages/Gradients/Gradients';
import * as Datalab from './pages/Datalab/Datalab';
import * as Users from './pages/Users/Users';
import * as Retrica from './pages/Retrica/Retrica';
import { Col, FloatButton, Layout, Menu, Row, Tag } from 'antd';
import appStyle from 'styles/app.module.css';
import { AuthProvider } from 'components/Auth/AuthProvider';
import Login from 'pages/Auth/Login';
import { RequireAuth } from 'components/Auth/RequireAuth';
import ACL from 'pages/Auth/ACL';
import { useEffect, useState } from 'react';
import { Grapeseed } from 'api/grapeseed';
import { ulid } from 'ulid';
import { AccountView } from 'components/Auth/AccountView';

const { Header, Content, Sider } = Layout;

// App Menu

const topMenuItems: AppMenuItem[] = [
  {
    key: 'packs',
    label: 'Packs',
  },
  {
    key: 'retrica',
    label: 'Retrica',
  },
  {
    key: 'settings',
    label: 'Settings',
  },
];

const siderMenuItemsPacks: AppMenuItem[] = [
  {
    key: 'admin',
    label: 'Admin',
    children: [
      {
        key: 'adminTagsSuggested',
        url: '/admin/tagsSuggested',
        label: 'Suggested Tags',
      },
      {
        key: 'adminSafeSearch',
        url: '/admin/safeSearch',
        label: 'Safe Search',
      },
    ],
  },
  {
    key: 'bundle',
    label: 'Bundles',
    children: [
      {
        key: 'bundleNew',
        url: '/bundles/new',
        label: 'Add a new bundle',
      },
      {
        key: 'bundles',
        url: '/bundles/list',
        label: 'Manage bundles',
      },
      {
        key: 'bundleList',
        url: '/bundles/bundleList',
        label: 'Manage bundle list',
      },
    ],
  },
  {
    key: 'materials',
    label: 'Materials',
    children: [
      {
        key: 'templates',
        url: '/materials/templates',
        label: 'Templates',
      },
      {
        key: 'layouts',
        url: '/materials/layouts',
        label: 'Layouts',
      },
      {
        key: 'stickers',
        label: 'Stickers',
        children: [
          {
            key: 'stickers-new',
            url: '/materials/stickers/new',
            label: 'Add a new sticker',
          },
          {
            key: 'stickers-bulk',
            url: '/materials/stickers/bulk',
            label: 'Bulk upload',
          },
          {
            key: 'stickers-new-with-urls',
            url: '/materials/stickers/newwithurls',
            label: 'Add with URLs',
          },
        ],
      },
      {
        key: 'fonts',
        url: '/materials/fonts',
        label: 'Fonts',
      },
      {
        key: 'backgrounds',
        label: 'Backgrounds',
        children: [
          {
            key: 'backgrounds-new',
            url: '/materials/backgrounds/new',
            label: 'Add a new background',
          },
          {
            key: 'backgrounds-bulk',
            url: '/materials/backgrounds/bulk',
            label: 'Bulk upload',
          },
          {
            key: 'backgrounds-new-with-urls',
            url: '/materials/backgrounds/newwithurls',
            label: 'Add with URLs',
          },
        ],
      },
      {
        key: 'placeholders',
        url: '/materials/placeholders',
        label: 'Placeholders',
      },
      {
        key: 'colors',
        url: '/materials/colors',
        label: 'Colors',
      },
      {
        key: 'gradients',
        url: '/materials/gradients',
        label: 'Gradients',
      },
      {
        key: 'combos',
        url: '/materials/combos',
        label: 'Combos',
      },
      {
        key: 'texts',
        url: '/materials/texts',
        label: 'Texts',
      },
      {
        key: 'shapes',
        url: '/materials/shapes',
        label: 'Shapes',
      },
    ],
  },
  {
    key: 'packs',
    label: 'Packs',
    children: [
      {
        key: 'packs-recent',
        url: '/packs/recent',
        label: 'Recent packs',
      },
      {
        key: 'packs-add',
        url: '/packs/add',
        label: 'Add a pack',
      },
    ],
  },
  {
    key: 'search',
    label: 'Search',
    children: [
      {
        key: 'search-materials',
        url: '/search/materials',
        label: 'Search materials',
      },
      {
        key: 'search-packs',
        url: '/search/packs',
        label: 'Search packs',
      },
    ],
  },
  {
    key: 'messaging',
    label: 'Messaging',
    children: [
      {
        key: 'messaging-build',
        url: '/messaging/build',
        label: 'Send',
      },
      {
        key: 'messaging-messages',
        url: '/messaging/messages',
        label: 'Messages',
      },
    ],
  },
  {
    key: 'creators',
    label: 'Creators',
    url: '/creators',
  },
  {
    key: 'datalab',
    label: 'Data lab',
    children: [
      {
        key: '/datalab/materials',
        url: '/datalab/materials',
        label: 'Materials',
      },
      {
        key: '/datalab/keywords',
        url: '/datalab/keywords',
        label: 'Search Keywords',
      },
      {
        key: '/datalab/packs-created',
        url: '/datalab/packs/created',
        label: 'Packs Created',
      },
    ],
  },
];

const siderMenuItemsRetrica: AppMenuItem[] = [
  {
    key: '/presets',
    label: 'Presets',
    children: [
      {
        key: '/presets/recommendation',
        label: 'Recommendation',
        url: '/retrica/presets/recommendation',
      },
      {
        key: '/presets/search',
        label: 'Search',
        url: '/retrica/presets/search',
      },
    ],
  },
  {
    key: '/lookuptables/new',
    url: '/retrica/lookuptables/new',
    label: 'LookUp Tables',
  },
  {
    key: 'IAP',
    url: '/retrica/iap',
    label: 'IAP (In App Purchase)',
  },
  {
    key: '/lookerstudio',
    url: '/retrica/lookerstudio',
    label: 'Looker Studio',
  },
];

const siderMenuItemsSettings: AppMenuItem[] = [
  {
    key: 'acl',
    url: '/acl',
    label: 'ACL (Access Control List)',
  },
  {
    key: 'users',
    label: 'Users',
    children: [
      {
        key: '/users/recent',
        url: '/users/recent',
        label: 'Recent',
      },
      {
        key: '/users/search',
        url: '/users/search',
        label: 'Search',
      },
    ],
  },
];

// App Routes

function AppRoutes() {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/admin"
        element={
          <RequireAuth>
            <Admin.Main />
          </RequireAuth>
        }
      >
        <Route path="/admin/tagsSuggested" element={<Admin.TagsSuggested />} />
        <Route path="/admin/safeSearch" element={<Admin.SafeSearch />} />
      </Route>
      <Route
        path="/bundles"
        element={
          <RequireAuth>
            <Bundles.Main />
          </RequireAuth>
        }
      >
        <Route path="/bundles/new" element={<Bundles.New />} />
        <Route path="/bundles/list" element={<Bundles.BundleList />} />
        <Route path="/bundles/bundleList" element={<Bundles.BundleLists />} />
      </Route>
      <Route
        path="/materials"
        element={
          <RequireAuth>
            <Materials.Main />
          </RequireAuth>
        }
      >
        <Route path="/materials/templates" element={<Templates.New />} />
        <Route path="/materials/layouts" element={<Layouts.New />} />
        <Route path="/materials/stickers/new" element={<Stickers.New />} />
        <Route path="/materials/stickers/bulk" element={<Stickers.Bulk />} />
        <Route
          path="/materials/stickers/newwithurls"
          element={<Stickers.NewWithUrls />}
        />
        <Route path="/materials/fonts" element={<Fonts.New />} />
        <Route
          path="/materials/backgrounds/new"
          element={<Backgrounds.New />}
        />
        <Route
          path="/materials/backgrounds/bulk"
          element={<Backgrounds.Bulk />}
        />
        <Route
          path="/materials/backgrounds/newwithurls"
          element={<Backgrounds.NewWithUrls />}
        />
        <Route path="/materials/placeholders" element={<Placeholders.New />} />
        <Route path="/materials/colors" element={<Colors.New />} />
        <Route path="/materials/combos" element={<Combos.List />} />
        <Route path="/materials/texts" element={<Texts.New />} />
        <Route path="/materials/shapes" element={<Shapes.New />} />
        <Route path="/materials/gradients" element={<Gradients.New />} />
        <Route path="/materials/:id" element={<Materials.SingleItem />} />
      </Route>
      <Route
        path="/packs"
        element={
          <RequireAuth>
            <Packs.Main />
          </RequireAuth>
        }
      >
        <Route path="/packs/recent" element={<Packs.Recent />} />
        <Route
          path="/packs/add"
          element={
            <Packs.EditPack
              id={ulid()}
              onSubmit={() => navigate('/packs/recent')}
            />
          }
        />
        <Route path="/packs/:id" element={<Packs.SingleItem />} />
      </Route>
      <Route
        path="/search"
        element={
          <RequireAuth>
            <Search.Main />
          </RequireAuth>
        }
      >
        <Route path="/search/materials" element={<Search.Materials />} />
        <Route path="/search/packs" element={<Search.Packs />} />
      </Route>
      <Route
        path="/messaging"
        element={
          <RequireAuth>
            <Messaging.Main />
          </RequireAuth>
        }
      >
        <Route path="/messaging/build" element={<Messaging.Build />} />
        <Route path="/messaging/messages" element={<Messaging.Messages />} />
      </Route>
      <Route
        path="/creators"
        element={
          <RequireAuth>
            <Creators.Main />
          </RequireAuth>
        }
      >
        <Route path="/creators/" element={<Creators.CreatorList />} />
        <Route path="/creators/:code" element={<Creators.SingleItem />} />
      </Route>
      <Route
        path="/datalab"
        element={
          <RequireAuth>
            <Datalab.Main />
          </RequireAuth>
        }
      >
        <Route path="/datalab/materials" element={<Datalab.Materials />} />
        <Route path="/datalab/keywords" element={<Datalab.Keywords />} />
        <Route
          path="/datalab/packs/created"
          element={<Datalab.CreatedPacks />}
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/acl"
        element={
          <RequireAuth>
            <ACL />
          </RequireAuth>
        }
      />
      <Route
        path="/users"
        element={
          <RequireAuth>
            <Users.Main />
          </RequireAuth>
        }
      >
        <Route path="/users/recent" element={<Users.Recent />} />
        <Route path="/users/search" element={<Users.Search />} />
        <Route path="/users/:id" element={<Users.SingleItem />} />
      </Route>
      <Route
        path="/retrica/presets"
        element={
          <RequireAuth>
            <Retrica.Presets.Main />
          </RequireAuth>
        }
      >
        <Route
          path="/retrica/presets/search"
          element={<Retrica.Presets.Search />}
        />
        <Route
          path="/retrica/presets/recommendation"
          element={<Retrica.Presets.Recommendation />}
        />
      </Route>
      <Route
        path="/retrica/lookuptables"
        element={
          <RequireAuth>
            <Retrica.LookUpTables.Main />
          </RequireAuth>
        }
      >
        <Route
          path="/retrica/lookuptables/new"
          element={<Retrica.LookUpTables.New />}
        />
      </Route>
      <Route
        path="/retrica/iap"
        element={
          <RequireAuth allowed="cs">
            <Retrica.Iap.Search />
          </RequireAuth>
        }
      />
      <Route
        path="/retrica/lookerstudio"
        element={
          <RequireAuth>
            <Retrica.LookerStudio.Main />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
// App Components

export default function App() {
  const [selectedTopMenu, setSelectedTopMenu] = useState('packs');
  const [apiSeverVersion, setApiServerVersion] = useState('');
  let environment = process.env.REACT_APP_ENV;
  let apiServerUrl = process.env.REACT_APP_GRAPESEED_URL;

  useEffect(() => {
    async function fetchVersion() {
      let result = await Grapeseed.GET('/api/configs/version');
      setApiServerVersion(result['version']);
    }
    fetchVersion();
  }, []);

  const siderMenuItems = (topMenu: string) => {
    switch (topMenu) {
      case 'packs':
        return siderMenuItemsPacks;
      case 'retrica':
        return siderMenuItemsRetrica;
      case 'settings':
      default:
        return siderMenuItemsSettings;
    }
  };

  return (
    <AuthProvider>
      <Layout>
        <Header className="header" style={{ backgroundColor: 'white' }}>
          <Row align="middle" gutter={16}>
            <Col>
              <span className={appStyle.appTitle}>Grapeseed</span>
              <Tag color={environment === 'production' ? '#027bff' : '#16a1b8'}>
                <span style={{ fontWeight: 'bold' }}>
                  {`${environment.toUpperCase()} ${
                    process.env.REACT_APP_VERSION ?? 'LocalBuild'
                  }`}
                </span>
              </Tag>
            </Col>
            <Col flex="auto">
              <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={[selectedTopMenu]}
                onClick={(menuInfo) => setSelectedTopMenu(menuInfo.key)}
                items={topMenuItems}
              />
            </Col>
            <Col>
              <Tag
                color={
                  apiServerUrl === 'https://grapeseed.retrica.io'
                    ? '#027bff'
                    : '#16a1b8'
                }
              >
                <span style={{ fontWeight: 'bold' }}>
                  API server:
                  {` ${apiServerUrl} @ ${apiSeverVersion}`}
                </span>
              </Tag>
            </Col>
            <Col flex={'none'}>
              <AccountView showButton={false} />
            </Col>
          </Row>
        </Header>
        <Layout>
          <Sider width={200} className={appStyle.siteLayoutSider}>
            <AppMenu items={siderMenuItems(selectedTopMenu)} />
          </Sider>
          <Content className={appStyle.siteLayoutContent}>
            <FloatButton.BackTop />
            <AppRoutes />
          </Content>
        </Layout>
      </Layout>
    </AuthProvider>
  );
}
