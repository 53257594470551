import { PageTitle } from 'components/PageTitles';

export function Main() {
  return (
    <>
      <PageTitle>Looker Studio</PageTitle>
      <iframe
        title="Retrica Looker Studio"
        width="100%"
        height="900"
        src="https://lookerstudio.google.com/embed/reporting/d68814a7-570f-4e2d-9287-d20c29669113/page/p_7jcq96ownd"
        style={{ border: 0 }}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </>
  );
}
