import { useCallback, useRef } from 'react';
import { Upload, Button } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import componentStyle from 'styles/common.module.css';
import { ApiError, OnError } from 'utils/errors';
import { Grapeseed } from 'api/grapeseed';
import { ulid } from 'ulid';
import { Retriver } from 'api/retriver/retriver';
import { NewFileUploadUrlRequest } from 'api/retriver/requestFactory/files';
import { NewFileDeleteRequest } from 'api/retriver/requestFactory/files';

export interface FileUploaderProps {
  storage?: 'grapeseed' | 'retriver';
  accept?: string;
  container: string; // for retriver, container is fixed to 'retrica' / 'retrica-us/eu/asia'
  filename?: string; // nil if for content, pass Material ID if for thumbnail
  suffix?: string; // filename (including uploaded timestamp) will be followed by this suffix string
  cache?: { maxAge: number; sMaxAge: number };
  direction?: 'row' | 'column';
  disabled?: boolean;
  onAdd?: (file: UploadFile, id: string) => void;
  onRemove?: (id: string) => void;
  onChange?: (value: UploadFile[]) => void;
}

export function FileUploader(props: FileUploaderProps) {
  const uploadUrl = useRef<string | undefined>();
  const token = useRef<string | undefined>();
  const uploadName = useRef<string>(props.filename ?? ulid());
  const defaultCacheAge = 31536000;

  const beforeUpload = useCallback(
    async (file: UploadFile) => {
      let result;
      if (!props.storage || props.storage === 'grapeseed') {
        // default 'grapeseed'
        const ext = file.name.split('.').pop();
        uploadName.current =
          `${uploadName.current.split('_')[0]}_${Date.now().toString()}` +
          (props.suffix ?? '');
        result = await Grapeseed.GET('/api/files/uploadurl', {
          queryParams: new Map<string, string>([
            ['container', props.container],
            ['name', `${uploadName.current}.${ext}`],
          ]),
        });
      } else if (props.storage === 'retriver') {
        let fnSplit = props.filename?.split('/');
        if (!fnSplit || fnSplit.length < 2) {
          return false;
        }
        const dir = fnSplit[0];
        const path = fnSplit.slice(1).join('/');
        const ext = file.name.split('.').pop();
        result = (
          await Retriver.do(
            NewFileUploadUrlRequest(dir, `${path}${props.suffix ?? ''}.${ext}`)
          )
        )?.fileUploadUrlResponse;
      }
      if (result) {
        uploadUrl.current = result['url'];
        token.current = result['token'];
      } else {
        return false;
      }
    },
    [props.container, props.filename, props.storage, props.suffix]
  );

  const doUpload = useCallback(
    async (options: RcCustomRequestOptions) => {
      const { onSuccess, onProgress, onError } = options;
      const file = options.file as File;
      const request = new XMLHttpRequest();
      if (!uploadUrl.current || !token.current) {
        return;
      }
      const requestUrlString = `${uploadUrl.current}?${token.current}`;
      const params = new URL(requestUrlString).searchParams;
      const apiVersionString = params.get('sv') ?? '2021-04-10';

      request.open('PUT', requestUrlString);
      request.setRequestHeader('x-ms-version', apiVersionString);
      request.setRequestHeader('x-ms-date', new Date().toUTCString());
      request.setRequestHeader('Content-Type', file.type);
      request.setRequestHeader('x-ms-blob-type', 'BlockBlob');
      request.setRequestHeader('x-ms-access-tier', 'Cold');
      request.setRequestHeader(
        'x-ms-blob-cache-control',
        `public s-maxage=${props.cache?.sMaxAge ?? defaultCacheAge}, max-age=${
          props.cache?.maxAge ?? defaultCacheAge
        }`
      );
      request.setRequestHeader('Access-Control-Allow-Origin', '*');

      request.upload.addEventListener('progress', (e) => {
        onProgress?.({ percent: (e.loaded / e.total) * 100 });
      });

      request.timeout = 15000;
      request.ontimeout = (e) => {
        OnError(e);
      };

      request.onreadystatechange = (e) => {
        if (request.readyState === 4) {
          if (request.status >= 200 && request.status < 300) {
            onSuccess?.(file);
          } else {
            const err = new ApiError(
              request.status,
              request.statusText,
              request.response
            );
            onError?.(err);
            OnError(err);
          }
          uploadUrl.current = undefined;
          token.current = undefined;
        }
      };

      request.send(file);
    },
    [props.cache?.maxAge, props.cache?.sMaxAge]
  );

  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      let file = info.file;
      if (file.status === 'done' && uploadUrl.current) {
        file.url = uploadUrl.current;
        props.onAdd?.(file, uploadName.current.split('_')[0]);
      } else if (file.status === 'removed') {
        props.onRemove?.(uploadUrl.current ?? '');
        uploadUrl.current = undefined;
      }
      props.onChange?.(info.fileList);
    },
    [props]
  );

  const handleRemove = useCallback(
    (file: UploadFile) => {
      const ext = file.name.split('.').pop();
      if (file.url) {
        switch (props.storage) {
          case 'grapeseed':
            Grapeseed.DELETE(`/api/files/${uploadName.current}.${ext}`, {
              queryParams: new Map<string, string>([
                ['container', props.container],
              ]),
            });
            return;
          case 'retriver':
            Retriver.do(NewFileDeleteRequest(file.url));
            return;
        }
      }
    },
    [props]
  );

  return (
    <Upload
      className={
        props.direction === 'column'
          ? componentStyle.flexColumn
          : componentStyle.flexRow
      }
      accept={props.accept}
      maxCount={1}
      multiple={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      onRemove={handleRemove}
      customRequest={doUpload}
      listType={'picture'}
      disabled={props.disabled ?? false}
    >
      <Button
        className={componentStyle.flexItemRightMargin}
        type="primary"
        icon={<UploadOutlined />}
      >
        Upload a file
      </Button>
    </Upload>
  );
}

export function acceptTypesInfo(typeStr: string) {
  return (
    <span className={componentStyle.hinttextOrange}>
      {'* Supported types: ' +
        typeStr
          .split(',')
          .map((s) => s.split('/').pop())
          .join(', ')}
    </span>
  );
}
